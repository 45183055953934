.welcome {
  p.learn-more, table.inline-statistics {
    a {
      font-weight: 500;
      text-decoration: none;
      color: $indigo;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p.learn-more {
    a {
      @include breadcrumb-icon(
        $size: 20px,
        $background-image: svg-load('images/breadcrumb-blue.svg'),
        $vertical-align: -15%
      );
    }
  }
}
