.welcome.plans {
  .filterable {
    h4 {
      margin: 4em auto 1em;
      text-align: center;

      font-size: 80%;
    }

    .annual-toggle {
      margin: 1em auto 2em;
      text-align: center;
    }
  }

  .column {
    > .plan {
      margin: 10px;
      border: thin solid $quiet-border;
      border-radius: 10px;
      overflow: hidden;

      > header {
        font-family: $alt-font-family;
        background: $dark-blue;
        color: $background;
        text-align: center;
        height: 135px;
        padding: 1em;

        > h1.title {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: $alt-font-weight-medium;
          letter-spacing: $alt-font-spacing;
          padding: 0.25em;
          color: $white;

          a {
            color: $white;
          }
        }

        > h2.cost {
          font-weight: normal;
          line-height: 75px;

          sup {
            font-size: 32px;
            position: relative;
            top: -20px;
            font-weight: $alt-font-weight;
          }

          sub {
            font-size: 20px;
          }

          span.number {
            font-family: $number-font-family;
            font-size: 70px;
          }

          span.free {
            font-size: 45px;
          }
        }
      }

      > aside {
        padding: 1em;
        font-size: 85%;
        font-family: $alt-font-family;
        height: 475px;

        ul.features {
          list-style: none;
          margin: 0;
          padding: 0;
          margin-bottom: 2em;

          li {
            margin: 1em 0;
            text-align: center;

            &.disabled {
              text-decoration: line-through;
              color: mix($quiet-border, $quiet-text, 75%);
            }
          }
        }

        a.button {
          display: block;
          margin: 5px;
          text-align: center;

          + a.button {
            margin-top: 15px;
          }

          &.extra-on-top {
            margin-top: 98px;
          }
        }
      }
    }
  }

  p.we-accept {
    span {
      display: inline-block;
      width: 44px;
      height: 44px;
      line-height: 44px;
      margin: 0.4em;
      text-indent: -900em;
      overflow: hidden;
      background-size: 40px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      vertical-align: middle;
    }

    .credit-card {
      background-image: url('/images/payment-methods/stripe.svg');
    }

    .bitcoin {
      background-image: url('/images/currencies/bitcoin.svg');
    }

    .bitcoin-cash {
      background-image: url('/images/currencies/bitcoin-cash.png');
      background-size: 44px;
    }

    .litecoin {
      background-image: url('/images/currencies/litecoin.svg');
    }

    .dogecoin {
      background-image: url('/images/currencies/dogecoin.svg');
    }

    .ethereum {
      background-image: url('/images/currencies/ethereum.svg');
      background-size: 25px;
    }

    .dash {
      background-image: url('/images/currencies/dash.svg');
    }

    .and-more {
      width: initial;
      text-indent: initial;
      margin-left: 1em;
      font-weight: $font-weight-medium;
    }

    a {
      opacity: 0.8;
      text-decoration: none;
      display: inline-block;
      margin-left: 2em * $whitespace;

      &:hover {
        text-decoration: none;
        opacity: 1;
        transition: opacity 0.2s;
      }
    }
  }

  .features {
    .column {
      .feature {
        @include feature-column-icons();

        &.charts > .icon {
          background-image: url('/images/badges/charts.svg');
        }
        &.notifications > .icon {
          background-image: url('/images/badges/bell.svg');
          background-size: 35px;
        }
        &.indicators > .icon {
          background-image: url('/images/badges/indicators.svg');
        }
        &.no-ads > .icon {
          background-image: url('/images/badges/no-ads.svg');
        }
        &.currencies > .icon {
          background-image: url('/images/badges/currencies.svg');
          background-size: 80px;
        }
        &.api > .icon {
          background-image: url('/images/badges/api.svg');
          background-size: 50px;
        }
        &.two-factor > .icon {
          background-image: url('/images/badges/castle.svg');
          background-size: 50px;
        }
        &.csv > .icon {
          background-image: url('/images/badges/export.svg');
          background-size: 35px;
        }
      }
    }
  }

  hr {
    max-width: $mobile * 0.9;
    background: $quiet-border;
    height: 2px;
    margin: 2.5em auto 0;
    border: 0;
  }
}
