@keyframes rotate-background {
  from { transform: rotate(0); -webkit-transform: rotate(0); }
  to { transform: rotate(360deg); -webkit-transform: rotate(360deg); }
}

.welcome.index {
  header.top {
    margin-top: 5em;
    margin-bottom: 2em;
  }

  &.first {
    // causes a nasty break > ~4000px wide.
    // possible solutions:
    // 1. put the background image on the div.content. doesn't play nicely
    // 2. float .first absolutely with a fixed height (that scales to the full height
    //    of the triangle) and place the following one relative. annoying
    // 3. new design that take this into consideration
    background-image: svg-load('images/home/triangle.svg');
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;

    padding-bottom: 0;

    .mockup {
      margin-top: 2.25em * $whitespace;

      img {
        max-width: 500px;
        display: block;
        margin: 0 auto;

        @media (max-width: $public-width) {
          width: 100%;
        }
      }
    }
  }

  nav.welcome {
    ul {
      list-style: none;
      margin: 1em * $whitespace;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 0;
        padding: 0 1em * $whitespace;

        a {
          color: $lighter-blue;
          font-family: $alt-font-family;
          font-weight: $alt-font-weight-medium;
          font-size: 90%;
          text-decoration: none;
          text-transform: uppercase;

          &:hover, &:active {
            text-decoration: underline;
            color: mix($lighter-blue, $dark-blue, 25%);
          }
        }

        + li {
          border-left: 2px solid $quiet-border;
          @media (max-width: $public-width) {
            border-left: 0;
          }
        }
      }
    }
  }

  .features {
    margin: 0 auto (2em * $whitespace) auto;

    .column {
      .feature {
        @include bubble-feature-column-icons();

        &.automated > .icon {
          background-image: url('/images/badges/automated-bubble.svg');
        }

        &.tax > .icon {
          background-image: url('/images/badges/calculator-bubble.svg');
        }

        &.charts > .icon {
          background-image: url('/images/badges/charts-bubble.svg');
        }

        &.alerts > .icon {
          background-image: url('/images/badges/bell-bubble.svg');
        }
      }
    }
  }

  .crypto-tax {
    max-width: $public-width * 0.9;

    .column {
      &.exchanges {
        span.image {
          display: block;
          background: url('/images/home/exchange-grid-horizontal.png') 100% 50% no-repeat;
          background-size: contain;
          width: 100%;
          height: 220px;

          @media (max-width: $mobile) {
            background: url('/images/home/exchange-grid-vertical.png') 50% 100% no-repeat;
            background-size: contain;
            height: 400px;
          }
        }
      }

      &.report {
        text-align: center;

        div {
          width: 80%;
          margin: 0 auto auto 0;
          @media (max-width: $mobile) {
            margin: 0 auto;
          }

          h3 {
            font-family: $alt-font-family;
            font-weight: $alt-font-weight-medium;
            color: $dark-blue;
            margin-bottom: 0.5em * $whitespace;
          }

          img {
            max-width: 100%;
          }

          p.learn-more {
            margin-top: 0;
            font-size: 80%;
            font-family: $alt-font-family;
            font-weight: $alt-font-weight-medium;
          }
        }
      }
    }
  }

  &.technology {
    padding: 0;

    @mixin technology-animation($circle-image-size, $banner-height) {
      position: relative;
      max-width: $circle-image-size;
      height: $banner-height;
      margin: 0 auto;
      overflow: hidden;

      > .image {
        position: absolute;
        width: $circle-image-size;
        height: $circle-image-size;
        top: -100px;
        left: 0;
        z-index: 1;

        background-image: url('/images/home/currencies.svg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: ($circle-image-size * 0.95);

        animation: rotate-background 240s linear infinite;
      }
    }

    > .wrapper {
      @include technology-animation(940px, 660px);

      @media (max-width: $public-width) {
        @include technology-animation(940px * 0.8, 660px * 0.9);
      }

      @media (max-width: $mobile) {
        > .image {
          display: none;
        }
      }

      > .content {
        text-align: center;
        padding: 1em * $whitespace;
        position: relative; // so z-index can be applied
        z-index: 2;

        p {
          max-width: 600px;
        }

        table.inline.statistics {
          border-radius: 5px;
          margin: 1em * $whitespace auto;
          max-width: 400px;
          background: $background;

          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
          padding: 1em * $whitespace;

          tbody {
            width: 100%;
          }

          th, td {
            line-height: $line-height * 1.3;
          }

          th {
            width: 80%;
            font-family: $alt-font-family;

            a {
              color: $text;
              text-decoration: none;
              font-weight: $alt-font-weight;

              &:hover, &:active {
                color: $black;
                text-decoration: underline;
              }
            }
          }
          td {
            width: 20%;
            font-weight: $alt-font-weight-medium;
            text-align: left;

            &.quiet {
              width: 100%;
              line-height: $line-height * 1; // reset

              font-size: 60%;
              color: $lighter-blue;
            }
          }
        }
      }
    }
  }

  > section.code-sample {
    .wrapper {
      max-width: 780px;
      margin: 0 auto;
    }

    div.CodeRay {
      width: 100%;
      margin: 0 auto;
    }

    pre {
      background: $background;
      border-radius: 10px;
      padding: (0.5em * $whitespace) (1em * $whitespace);
      box-shadow: 0 5px 15px rgba(0,0,0,0.1);
      white-space: pre-wrap;
      word-break: break-all;

      font-size: $font-size * 0.9;
      line-height: $line-height * 0.8;

      @media (max-width: $public-width) {
        font-size: $font-size * 0.8;
        line-height: $line-height * 0.7;
      }

      @media (max-width: $mobile) {
        font-size: $font-size * 0.6;
        line-height: $line-height * 0.5;
      }
    }
  }
}
