// For everything that's definitely on the public pages only

@import "variables";

body {
  div.public-2018-layout {
    background: $public-footer;
    color: $public-footer-text;

    display: table-cell;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    > div.header {
      background: $public-header;
      color: transparentize($public-header-text, 0.5);
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

      $public-mobile-resize: 0.77;

      > div.header-container {
        @include max-public-header-width(0.5em);

        height: 77px;
        position: relative;

        @media (max-width: $mobile) {
          height: 77px * $public-mobile-resize;
        }

        > nav {
          display: inline-block;
          position: absolute;
          top: 8px * $public-whitespace;
          @media (max-width: $mobile) {
            top: 8px * $public-whitespace * $public-mobile-resize;
          }
          right: 0;

          a {
            color: $public-header-text;
            @include no-partial-underline();

            &:hover {
              @include partial-underline();

              &.button {
                @include no-partial-underline();
              }
            }
          }

          > ul {
            list-style: none;
            display: inline-block;
            margin: 0;
            padding: 0;

            > li {
              margin: 0 (0.5em * $public-whitespace);
              display: inline-block;
              vertical-align: top;
              padding: 0;

              @media (max-width: $public-width) {
                margin: 0 (0.15em * $public-whitespace);
              }
              @media (max-width: $mobile) {
                font-size: $public-header-fontsize;
              }

              &.signup, &.portfolio {
                margin-top: -($button-top * 0.33);
              }

              &.hamburger-closer {
                display: none;

                @media (max-width: $mobile) {
                  display: inline-block;
                }
              }

              > a {
                font-family: $alt-font-family;
                font-size: 75%;
                font-weight: $alt-font-weight;
                text-transform: uppercase;
                letter-spacing: $alt-font-spacing * 3;

                &.button {
                  @include buttonize($candy-green, $public-header);

                  margin-top: -2px;
                  text-transform: initial;
                  letter-spacing: $alt-font-spacing;
                  font-weight: $alt-font-weight-medium;
                  text-shadow: initial;
                }
              }
            }
          }

          // dropdown menu for mobile
          @media (max-width: $mobile) {
            &.hamburger-target {
              position: absolute;
              top: 0;
              left: 0;
              width: 80%;
              max-width: $mobile / 2.5;
              background: $public-header;
              color: transparentize($public-header-text, 0.5);
              z-index: 100;
              margin: 0;
              padding: 1em;
              box-shadow: 4px 4px 4px rgba(0,0,0,0.5);

              display: block !important;
              left: 0;
              transition: left 0.2s;

              &.hidden {
                left: -500px;
                transition: left 0.2s;
              }

              > ul {
                > li {
                  display: block;
                  margin: 0 0;

                  + li {
                    margin-top: 1em;
                  }

                  &.portfolio {
                    margin-bottom: 0.5em;
                  }
                }
              }
            }
          }
        }

        > header {
          display: inline-block;
          position: absolute;
          top: 1px + 11px * $public-whitespace;
          @media (max-width: $mobile) {
            top: 1px + 11px * $public-whitespace * $public-mobile-resize;
          }
          left: 0;

          @media (max-width: $mobile) {
            width: 100%;
            text-align: center;
          }

          // cryptfolio logo
          h2 {
            margin: 0;
            padding: 0;
            font-size: $public-header-fontsize * 1.05;

            a {
              background: url('/images/logo-with-text-white.svg') no-repeat 0 0;
              background-size: contain;

              display: inline-block;
              text-indent: 900em;
              height: 50px;
              width: 160px;
              overflow: hidden;
              position: relative;
              top: -15px;

              @media (max-width: $mobile) {
                height: 50px * $public-mobile-resize;
                width: 160px * $public-mobile-resize;
                top: -15px * $public-mobile-resize;
              }
            }
          }
        }

        .hamburger-opener {
          background: svg-load('images/hamburger-white.svg') no-repeat 0 0;
          background-size: contain;
          position: absolute;
          display: none;
          width: 1.5em;
          height: 1.5em;
          overflow: hidden;
          text-indent: 900em;
          top: 21px;
          left: 21px;
          z-index: 1;

          @media (max-width: $mobile) {
            display: inline-block;
          }
        }
      }
    }

    > div.content {
      background: linear-gradient(180deg, $sky-blue, $background 340px, $background);
      color: $text;

      // the -5px spread hides the inset shadow from the left and right
      box-shadow: inset 0 (5px+5px) 10px -5px rgba(0,0,0,0.15),
          0 5px 10px rgba(0,0,0,0.15); // this removes the left & right shadows

      > section {
        padding: .75em * $public-whitespace;
        @media (max-width: $mobile) {
          padding: .75em * $public-whitespace * 0.5;
        }

        > section {
          @include max-public-width();
        }

        &.banner {
          background: $sky-blue;
        }

        &.closing {
          background: linear-gradient($sky-blue, $background);
        }
      }
    }

    // custom nav underlines for each page
    &.controller-plans.action-index {
      div.header li.pricing {
        a {
          @include partial-underline();

          &:hover {
            @include no-partial-underline();
          }
        }
      }
    }
  }

  @import "public/home";
  @import "public/pricing";
  @import "public/welcome";
  @import "public/legacy-tax";

  // items that are not in the welcome pages should not be here
}
