// TODO this page needs to be rewritten for public-2018
.welcome.tax {
  section.major {
    h1 {
      margin: 0;
      padding: 0;

      color: $indigo;
      text-transform: uppercase;
      text-align: center;
      font-size: 200%;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    h2 {
      margin: 0;
      margin-bottom: 1em;

      color: $indigo;
      font-size: 175%;
      font-weight: 500;
    }

    .columns {
      @include max-public-width(0);
      padding-top: $major-section-spacing;

      @media (max-width: $public-width) {
        padding-top: $major-section-spacing / 2;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      }

      @media (max-width: $mobile) {
        padding-top: $major-section-spacing / 4;
        padding-left: 20px;
        padding-right: 20px;
      }

      section.column {
        box-sizing: border-box;

        + section.column {
          @media (max-width: $public-width) {
            margin-top: 0;
          }
        }
      }

      @mixin major-columns-image-widths($major-column-text-width, $major-image-width, $major-image-height) {
        &.columns-1-2 section.column {
          width: $major-column-text-width;
          @media (max-width: $public-width) {
            width: 100%;
          }

          + section.column {
            width: 100% - $major-column-text-width;
            @media (max-width: $public-width) {
              width: 100%;
            }
          }

          &.image img {
            float: left;
            margin-left: 95% - $major-image-width;
            @media (max-width: $public-width) {
              float: none;
              margin: 0 auto;
            }
          }
        }

        &.columns-2-1 section.column {
          width: 100% - $major-column-text-width - 5%;
          @media (max-width: $public-width) {
            width: 100%;
          }

          + section.column {
            width: $major-column-text-width + 5%;
            @media (max-width: $public-width) {
              width: 100%;
            }
          }

          &.image img {
            float: right;
            margin-right: 98% - $major-image-width;

            @media (max-width: $public-width) {
              float: none;
              margin: 0 auto;
            }
          }
        }

        // collapse columns down on small screens
        // (since we changed the widths manually, we have to include this again)
        &.columns-1-2, &.columns-2-1 {
          section.column {
            @media (max-width: $public-width) {
              width: 100%;
              & + section.column {
                margin-top: 10px * $whitespace;
              }
            }
          }
        }

        .column {
          &.description {
            padding-top: 1em;
          }

          &.image {
            height: $major-image-height;
            overflow: hidden;

            @media (max-width: $public-width) {
              height: 13.5em;
              padding-top: 1em;
            }

            @media (max-width: $mobile) {
              height: 9em;
              padding-top: 0;
            }

            img {
              display: block;
              width: $major-image-width;
              border-radius: 5px;
              border: thin solid $quiet-border;
              box-shadow: 2px 2px 5px rgba(0,0,0,0.3);

              @media (max-width: $mobile) {
                width: 95%;
              }
            }
          }
        }
      }

      @include major-columns-image-widths($major-column-text-width, $major-image-width, 17.5em);

      &.detailed {
        @include major-columns-image-widths($major-column-text-width, $major-image-width * 1.1, 19em);
      }

      .column {
        &.image {
          div.chart {
            margin-bottom: 0;
            margin-right: 1em;
          }

          &.mobile-show {
            display: none;
            @media (max-width: $public-width) {
              display: block;
            }
          }

          &.mobile-hide {
            display: block;
            @media (max-width: $public-width) {
              display: none;
            }
          }
        }
      }

      + .columns {
        border-top: thin solid $quiet-border;
      }
    }
  }

  section.columns {
    .column.description {
      padding-top: 0;

      h3 {
        font-weight: $font-weight-medium;
      }
    }

    &.need-more {
      .column {
        min-height: 9em;

        padding: 1em;
        box-sizing: border-box;
      }
    }

    &.empty {
      padding-top: 0;
    }
  }

  img.banner {
    margin: 0 auto;
    margin-top: 2em * $whitespace;
    display: block;
    max-width: 100%;
  }
}
